.navbar-sevasoft {
    /*background: rgba(0,0,0,0.5);*/
    /*top: 0;*/
    /*background: none;*/
    background: rgba(0,0,0,0.1);
    /*background-color: transparent;*/
    /*background-color: rgba(0,0,0,0.5);*/
    /*color: #1a1e21;*/
    /*background-color: ffffff;*/
    /*background-color: #2d4f6b;*/
    /*background-color: none;*/
    /*background-color: #B3DEE5;*/
}


#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 1;
}
/*.navbar-sevasoft a {*/
/*    color: antiquewhite;*/
/*}*/
/*.navbar-sevasoft a:visited  {*/
/*    color: antiquewhite;*/
/*}*/

/*.navbar-sevasoft-toggler-icon {*/
/*    color: antiquewhite;*/
/*    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e")*/
/*}*/
/*.nav-link.active {*/
/*    color: antiquewhite;*/
/*}*/
/*.navbar-dark .navbar-nav .nav-link {*/
/*    color: black;*/
/*}*/

/*.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {*/
/*    color: black;*/
/*}*/

/*.navbar-dark .navbar-nav > .nav-link > a:hover {*/
/*    color: black;*/
/*    background-color: #0a53be;*/
/*}*/

/*.navbar .nav-link hover {*/
/*    color:pink;*/
/*}*/

/*.navbar-dark .navbar-nav .nav-link.visited {*/
/*    color: black;*/
/*}*/

/*.navbar-dark .navbar a:link, a:visited, a:hover, a:active {*/
/*    color: black;*/
/*}*/

