.App {
  margin-top: 0;
  /*position: fixed;*/

  text-align: center;
  /*background: #2D4F6B;*/
  background: #ffffff;
  /*background: -webkit-linear-gradient(to bottom, #2D4F6B 0%, #B3DEE5 1%, #2D4F6B 100%);*/
  /*background: -moz-linear-gradient(to bottom, #2D4F6B 0%, #B3DEE5 1%, #2D4F6B 100%);*/
  /*background: linear-gradient(to bottom, #B3DEE5 10%, #2D4F6B 150%);*/
  /*background: linear-gradient(to bottom, #2D4F6B 0%, #B3DEE5 1%, #2D4F6B 80%);*/
  min-height: 100vh;
  display: block;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App section {
  display: block;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App #carouselwelcome {
  /*background-image: url(../public/assets/img/uvod-provozovna2.webp);*/
  background-image: url(../public/assets/img/uvod-provozovna1-platno.webp);
  /*background-image: url(../public/assets/img/slunecniceVIP.webp);*/
  background-attachment: fixed;
  background-size: cover;
  /*background-position: 1% 100%;*/
  background-position-x: 50%;

  background-repeat: no-repeat;
  padding: 500px 0;
  /*padding: 0;*/

  color: #fff;
}

.App-section-carousel {
  font-family: 'Ms Madi', cursive;
  font-size: 4em;
  font-weight: bold;
}


.App-header {
  display: none;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-section-slogan {
  /*padding: 0.1rem;*/
  /*display: flex;*/
  display: inline-block;
}

.App-section-slogan .slogan-blok {
  margin-top: 1rem;
  margin-bottom: 1rem;

  padding: 1rem 1.5rem;
  text-align: justify;
  color: #2D4F6B;
  /*color: #FAE6B1;*/
  /*color: #FFA101;*/

  /*border-color: #2D4F6B;*/

  border: 1px;
  border-radius: 1rem;
  border-style: solid;
  border-color: #7d5b9f;

  align-items: center;
  font-size: calc(10px + 1.5vmin);
}



